<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>项目管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="项目列表" icon="md-paper">
            <normal-list
              :columnsFormatData="columnsFormatData"
              :askData="askData"
              :refreshData="update_count"
              main_width="1260px"
            ></normal-list>
          </TabPane>
          <Button
            @click="openModal('addProject')"
            slot="extra"
            style="margin-right: 5px"
            >添加项目</Button
          >
        </Tabs>
      </div>
      <Modal
        v-model="modalSwicth.addProject"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('addProject')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>新增项目</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('addProject')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Form
              ref="formProject"
              :model="formItem"
              :rules="ruleValidate"
              :label-width="250"
            >
              <FormItem label="项目名称：" prop="name" style="width: 500px">
                <Input v-model="formItem.name" />
              </FormItem>
              <FormItem
                label="项目编号："
                prop="archiveNo"
                style="width: 500px"
              >
                <Input v-model="formItem.archiveNo" placeholder />
              </FormItem>
            </Form>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit('formProject')"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button @click="handleReset" size="large" style="margin-left: 8px"
            >重置</Button
          >
          <Button
            size="large"
            @click="closeModal('addProject')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.alterProject"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('alterProject')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>标准修改</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('alterProject')"
        ></i>
        <div class="sc-content-wrapper">
          <Form
            ref="formProjectAlter"
            :model="formItemAlter"
            :rules="ruleValidate"
            :label-width="250"
          >
            <FormItem label="项目名称：" prop="name" style="width: 500px">
              <Input v-model="formItemAlter.name" />
            </FormItem>
            <FormItem label="项目编号：" prop="archiveNo" style="width: 500px">
              <Input v-model="formItemAlter.archiveNo" placeholder />
            </FormItem>
          </Form>
        </div>
        <div slot="footer">
          <Button size="large" @click="modal_save" class="confirm-button"
            >确定</Button
          >
          <Button
            size="large"
            @click="closeModal('alterProject')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.ruleIn"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('ruleIn')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>导入标准</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('ruleIn')"
        ></i>
        <div class="sc-content-wrapper">
          <Form
            ref="formProjectAlter"
            :model="formItemAlter"
            :rules="ruleValidate"
            :label-width="250"
          >
            <FormItem label="选择标准：" prop="name" style="width: 700px">
              <Select v-model="choosedStandards" multiple>
                <Option
                  :value="s.id"
                  v-for="(s, index) in standardList"
                  :key="index"
                  >{{ s.standard_no + "《" + s.name + "》" }}</Option
                >
              </Select>
            </FormItem>
          </Form>
        </div>
        <div slot="footer">
          <Button size="large" @click="ruleInSubmit" class="confirm-button"
            >确定</Button
          >
          <Button
            size="large"
            @click="closeModal('ruleIn')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import normalList from "@/components/Common/NormalList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));

function formItemFac() {
  return {
    id: 0,
    name: "",
    archiveNo: "",
    otherInfo: {},
  };
}

export default {
  name: "projectManage",
  components: {
    normalList,
  },
  props: {},
  data() {
    return {
      askData: "projectList",
      update_count: 1,
      standardList: [],
      choosedStandards: [],
      columnsFormatData: [
        {
          title: "项目编号",
          width: 200,
          align: "center",
          key: "archive_no",
        },
        {
          title: "项目名称",
          key: "name",
          align: "center",
          render: (h, p) => {
            let url = "/manage/projectContent/" + p.row.enid;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                },
                style:{
                  color:'blue'
                }
              },
              p.row.name
            );
          }
        },
        {
          title: "创建时间",
          width: 180,
          align: "center",
          key: "create_time",
        },
        {
          title: "创建人",
          width: 110,
          align: "center",
          key: "creator",
        },
        {
          title: "状态",
          width: 110,
          align: "center",
          key: "stat",
        },
        {
          title: "操作",
          align: "center",
          width: 300,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "green" },
                  on: {
                    click: () => {
                      this.modalSwicth.alterProject = true;
                      this.formItemAlter.id = params.row.id;
                      this.formItemAlter.name = params.row.name;
                      this.formItemAlter.achiveNo = params.row.archive_no;
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "blue" },
                  on: {
                    click: () => {
                      this.currentProjectId = params.row.enid;
                      this.modalSwicth.ruleIn = true;
                    },
                  },
                },
                "导入标准"
              ),
            ]);
          },
        },
      ],
      modalSwicth: {
        addProject: false,
        alterProject: false,
        ruleIn: false,
      },
      currentProjectId: 0,
      formItem: formItemFac(),
      formItemAlter: formItemFac(),
      ruleValidate: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入项目名称！",
            trigger: "blur",
          },
        ],
      },
      loadingStatus: false,
      atimated: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getStaticOption();
  },
  methods: {
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    getStaticOption() {
      this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "standardList",
        })
        .then((response) => {
          if (response.data.stat == "ok") {
            this.standardList = response.data.res_record;
          } else {
            this.$Massage.error("获取选项数据失败，网络存在问题！");
          }
        });
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content: "<p>" + "项目：" + value.name + "</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {},
      });
    },
    ruleInSubmit() {
      if(this.choosedStandards.length>0){
        let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "ruleIn");
      form.append("project_id", this.currentProjectId);
      form.append("info", JSON.stringify(this.choosedStandards));
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/project_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("导入成功！");
            this.modalSwicth.ruleIn = false;
            window.open('/manage/projectContent/'+this.currentProjectId,'_blank');
          }else{
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
      }else{
        this.$Message.error('未选择标准！');
      }
    },
    recordFormatDel(id) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "delProject");
      form.append("id", id);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/project_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    handleReset() {
      for (let item in this.formItem) {
        this.formItem[item] = "";
      }
    },
    modal_save() {
      this.$refs["formProjectAlter"].validate((valid) => {
        if (valid) {
          let fileObj = this.fileAlter;
          let form = new FormData();
          form.append("file", fileObj);
          form.append("userToken", userToken);
          form.append("askData", "alterProject");
          form.append("info", JSON.stringify(this.formItemAlter));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/project_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("标准信息修改成功！");
                this.modalSwicth.alterProject = false;
                this.formItemAlter = formItemFac();
                this.update_count++;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch((error) => {
              this.fileAlter = null;
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let fileObj = this.file;
          let form = new FormData();
          form.append("file", fileObj);
          form.append("userToken", userToken);
          form.append("askData", "addProject");
          form.append("info", JSON.stringify(this.formItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/project_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("项目添加成功！");
                this.update_count++;
                this.modalSwicth.addProject = false;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch((error) => {
              this.file = null;
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}
</style>